let origin = "https://future.zhuneng.cn";
//小和互动
const postInteractionUrl = `/placeApi/front/wechatInteractiveRecord/add`;
//小和互动
const myInteractionUrl = `/placeApi/front/wechatInteractiveRecord/mineList`;
// 反馈列表
const findFeedbackPageUrl = `/serveApi/mini/feedback/findFeedbackPage`;
//反馈保存
const saveOrUpdateFeedbackUrl = `/serveApi/mini/feedback/saveOrUpdateFeedback`;

export {
  findFeedbackPageUrl,
  saveOrUpdateFeedbackUrl,
  postInteractionUrl,
  myInteractionUrl,
};
